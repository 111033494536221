//import './assets/main.css'
import './assets/style.css'
//import 'carbon-components/css/carbon-components.css';
//import CarbonComponentsVue from '@carbon/vue';
//import PrimeVue from 'primevue/config';
//import Lara from '@/presets/lara';      //import preset
//import PrimeVue from 'primevue/config';
//import Aura from '@primevue/themes/aura';


import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
//app.use(CarbonComponentsVue);
/*app.use(PrimeVue, {
  unstyled: false,
  theme: {
    preset: Aura
  }
});*/

/*app.use(PrimeVue, {
  unstyled: true,
  pt: Lara                            //apply preset
});*/
app.use(router)

app.mount('#app')
// infinity scrll und immer nach dem shema und shema randomizen