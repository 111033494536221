import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';

const axiosInstance = axios.create({
  //baseURL: 'http://127.0.0.1:8070', // replace with your backend URL
  baseURL: 'https://api.gifter.at', // replace with your backend URL
  withCredentials: true, // to send cookies with requests
});

axiosInstance.interceptors.request.use(
  config => {
    const authStore = useAuthStore();
    if (authStore.isLoggedIn && authStore.token) {
      config.headers.Authorization = `Bearer ${authStore.token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const authStore = useAuthStore();
      try {
        await authStore.renewToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.token}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        authStore.logout();
        await router.push('/login');
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
