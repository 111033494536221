<template>
  <div>
   <!-- <h1 class="mt-12">AdminLayout</h1>
    <cv-header aria-label="Carbon header">
      <cv-skip-to-content href="#main-content">
        Skip to content
      </cv-skip-to-content>
      <cv-header-name href="javascript:void(0)" prefix="Gifter">
        Admin
      </cv-header-name>
      <cv-header-nav aria-label="Carbon nav">
        <router-link to="/admin/create-product" custom v-slot="{ navigate, href }">
          <cv-header-menu-item :href="href" @click="navigate">
            Create Product
          </cv-header-menu-item>
        </router-link>
        <router-link to="/admin/manage-categories-and-purposes" custom v-slot="{ navigate, href }">
          <cv-header-menu-item :href="href" @click="navigate">
            Manage Categories and Purposes
          </cv-header-menu-item>
        </router-link>
        <router-link to="/admin/products" custom v-slot="{ navigate, href }">
          <cv-header-menu-item :href="href" @click="navigate">
            Products
          </cv-header-menu-item>
        </router-link>
        <cv-header-menu aria-label="Link 3" title="Link 3">
          <router-link to="javascript:void(0)" custom v-slot="{ navigate, href }">
            <cv-header-menu-item :href="href" @click="navigate">
              Submenu Link 1
            </cv-header-menu-item>
          </router-link>
          <router-link to="javascript:void(0)" custom v-slot="{ navigate, href }">
            <cv-header-menu-item :href="href" @click="navigate">
              Submenu Link 2
            </cv-header-menu-item>
          </router-link>
          <router-link to="javascript:void(0)" custom v-slot="{ navigate, href }">
            <cv-header-menu-item :href="href" @click="navigate">
              Submenu Link 3
            </cv-header-menu-item>
          </router-link>
        </cv-header-menu>
      </cv-header-nav>
      <template v-slot:header-global>
        <cv-header-global-action
          aria-label="Notifications"
          aria-controls="notifications-panel"
          label="Notifications"
          tipPosition="bottom"
          tipAlignment="start"
        >
        </cv-header-global-action>
        <cv-header-global-action
          aria-label="User avatar"
          aria-controls="user-panel"
          label="Log in"
          tipPosition="bottom"
          tipAlignment="center"
        >

        </cv-header-global-action>
        <cv-header-global-action
          aria-label="App switcher"
          aria-controls="switcher-panel"
          label="App switcher"
          tipPosition="bottom"
          tipAlignment="end"
        >
        </cv-header-global-action>
      </template>
      <template v-slot:right-panels>
        <cv-header-panel id="user-panel">
          An empty user panel
        </cv-header-panel>
        <cv-header-panel id="notifications-panel">
          An empty panel
        </cv-header-panel>
        <cv-header-panel id="switcher-panel">
          <cv-switcher>
            <cv-switcher-item>
              <router-link to="javascript:void(0)" custom v-slot="{ navigate, href }">
                <cv-switcher-item-link :href="href" @click="navigate" selected>
                  Selected app
                </cv-switcher-item-link>
              </router-link>
            </cv-switcher-item>
            <cv-switcher-item>
              <router-link to="javascript:void(0)" custom v-slot="{ navigate, href }">
                <cv-switcher-item-link :href="href" @click="navigate">
                  Other app
                </cv-switcher-item-link>
              </router-link>
            </cv-switcher-item>
            <cv-switcher-item>
              <router-link to="javascript:void(0)" custom v-slot="{ navigate, href }">
                <cv-switcher-item-link :href="href" @click="navigate">
                  Yet another app
                </cv-switcher-item-link>
              </router-link>
            </cv-switcher-item>
          </cv-switcher>
        </cv-header-panel>
      </template>
    </cv-header>
    <nav>
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li><router-link to="/logout">Logout</router-link></li>
      </ul>
    </nav>-->


    <nav class="bg-white border-gray-200 dark:bg-gray-900">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Gifter Logo" />
          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Gifter</span>
        </a>
        <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
        <div class="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <router-link to="/admin/create-product">
              <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Create Product</a>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/import-products">
              <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Import Products</a>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/manage-categories-and-purposes">
                <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Manage Categories and Purposes</a>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/products">
                <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Products</a>
              </router-link>
            </li>
            <li>
              <router-link to="/logout">
                <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">ausloggen</a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style>
</style>
