// src/stores/auth.js or src/stores/auth.ts
import { defineStore } from 'pinia';
import { login, renewToken } from '@/services/authService';
import axiosInstance from '@/services/axiosInstance';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('token') || null,
    userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
    status: 'idle',
  }),
  actions: {
    async login(email: string, password: string) {

      this.status = 'loading';
      try {
        const response = await login(email, password);
        this.setToken(response.access_token);
        this.setUserId(response.user_id);
        this.status = 'success';
      } catch (error) {
        this.status = 'error';
        throw error;
      }
    },
    async renewToken() {
      this.status = 'loading';
      try {
        const response = await renewToken();
        this.setToken(response.access_token);
        this.status = 'success';
      } catch (error) {
        this.status = 'error';
        throw error;
      }
    },
    logout() {
      this.setToken(null);
      this.setUserId(null);
    },
    setToken(token: string | null) {
      this.token = token;
      if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('token', token);
      } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
        localStorage.removeItem('token');
      }
    },
    setUserId(userId: number | null) {
      this.userId = userId;
      if (userId !== null) {
        localStorage.setItem('userId', String(userId));
      } else {
        localStorage.removeItem('userId');
      }
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    isAdmin: (state) => state.userId === 1,
    tokengetter: (state) => state.token,
  },
});
