import { createRouter, createWebHistory } from 'vue-router'
import PublicLayout from '@/layout/PublicLayout.vue'
import AppLayout from '@/layout/AppLayout.vue'
import AdminLayout from '@/layout/AdminLayout.vue'
import { useAuthStore } from '@/stores/auth'

const routes = [
  {
    path: '/login',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/Login.vue')
      }
    ]
  },
  {
    path: '/register',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/Register.vue')
      }
    ]
  },
  {
    path: '/geschenk-suchen',
    component: () => import('@/views/Products.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    component: () => {
      const authStore = useAuthStore()
      return Promise.resolve(authStore.isLoggedIn ? (authStore.isAdmin ? AdminLayout : AppLayout) : PublicLayout)
    },
    children: [
      {
        path: 'geschenk-suchen',
        component: () => import('@/views/Products.vue'),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: '',
        component: () => import('@/views/Products.vue'),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => {
      const authStore = useAuthStore()
      return Promise.resolve(authStore.isAdmin ? AdminLayout : AppLayout)
    },
    children: [
      {
        path: '',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/products',
    component:PublicLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/Products.vue'),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: '/product/:productId',
    component: () => {
      const authStore = useAuthStore()
      return Promise.resolve(authStore.isAdmin ? AdminLayout : AppLayout)
    },
    props: true,
    children: [
      {
        path: '',
        component: () => import('@/views/ProductDetail.vue'),
        name: 'ProductDetail',
        meta: {
          requiresAuth: false
        },
        props: true,
      }
    ]
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: 'create-product',
        component: () => import('@/views/admin/CreateProduct.vue'),
        meta: {
          requiresAuth: true,
          isAdminPage: true
        }
      },
      {
        path: 'import-products',
        component: () => import('@/views/admin/ImportProducts.vue'),
        meta: {
          requiresAuth: true,
          isAdminPage: true
        }
      },
      {
        path: 'edit-product/:productId',
        name: 'EditProduct',
        component: () => import('@/views/admin/EditProduct.vue'),
        meta: {
          requiresAuth: true,
          isAdminPage: true
        },
        props: true // Allow route params to be passed as props
      },
      {
        path: 'manage-categories-and-purposes',
        component: () => import('@/views/admin/ManageCategoriesAndPurposesAndInterests.vue'),
        meta: {
          requiresAuth: true,
          isAdminPage: true
        }
      },
      {
        path: 'products',
        component: () => import('@/views/admin/Products.vue'),
        meta: {
          requiresAuth: true,
          isAdminPage: true
        }
      }
    ]
  }
]

// IDE Complains because of the logout route, but it works fine
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (authStore.isLoggedIn) {
    if (to.path === '/login' || to.path === '/register') {
      // Redirect to dashboard if already authenticated
      next('/dashboard')
      return
    } else if (to.path === '/logout') {
      authStore.logout()
      next('/geschenk-suchen')
      return
    }
  }

  if (to.matched.some((record) => record.meta.isAdminPage)) {
    if (!authStore.isAdmin) {
      next(from)
      return
    } else {
      next()
      return
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authStore.isLoggedIn) {
      next('/login')
      return
    } else {
      next()
      return
    }
  } else {
    next()
  }
})

export default router
