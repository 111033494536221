// src/services/authService.ts

import axiosInstance from './axiosInstance';

interface LoginResponse {
  access_token: string;
  user_id: number;
}

const login = async (email: string, password: string): Promise<LoginResponse> => {
  const response = await axiosInstance.post('login', { email, password });
  return response.data;
};

const renewToken = async (): Promise<LoginResponse> => {
  const response = await axiosInstance.post('/renew');
  return response.data;
};

export { login, renewToken };
