<template>
  <div>
    <h1>Appllayout</h1>
    <nav>
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li><router-link to="/logout">Logout</router-link></li>
      </ul>
    </nav>
    <div>
      <router-view></router-view>
    </div>

  </div>
</template>

<script setup lang="ts">
</script>

<style>
</style>